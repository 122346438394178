<template>
  <!-- Traitement phytosanitaire -->
  <div class="volumes-non-epandus-subview">
    <teleport to="#header-action">
      <Btn
        v-if="helperService.userHasPermission('essaievenement_oedit')"
        class="action-btn"
        icon="create"
        color="white"
        hollow
        text="Modifier"
        :to="{
          name: 'essaiExperimentalTaskVolumesNonEpandusEdit',
          params: {
            id: $route.params.id,
            tid: $route.params.tid,
          }
        }"
      />
    </teleport>
    <div class="tabs-material-subtitle no-padding-bottom">
      <Container>
        <h2>Volumes non épandus</h2>
      </Container>
    </div>
    <Section>
      <Container>
        <div class="btn-bar btn-bar--right btn-bar--sub-h2">
          <Btn
            color="primary"
            hollow
            text="Imprimer la fiche"
            @click="download('pdf', 'evenement', $route.params.tid)"
          />
        </div>
        <Table
          :headers="headers"
          :static-headers="staticHeaders"
          :items="formattedModalities"
          :hiddenItems="['id', 'volume_applique']"
          :tools="false"
          :showModalitiesNumber="false"
        />
        <div class="margin-top">
          <h3>Validation</h3>
          <div class="grid">
            <KeyValue
              v-if="fiche?.date_validation"
              label="Date"
              :value="helperService.formatDateForInput(fiche?.date_validation)"
            />
            <KeyValue
              label="Trigramme"
              :value="fiche?.validation_trigramme"
            />
          </div>
        </div>
      </Container>
    </Section>
  </div>
  <Loader :active="loading"/>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Table from '@/components/table/Table.vue'
import Container from '@/components/layout/Container.vue'
import Loader from '@/components/layout/Loader.vue'
import Section from '@/components/layout/Section.vue'
import KeyValue from '@/components/form/KeyValue.vue'

export default {
  name: 'VolumesNonEpandusSubview',
  components: {
    KeyValue,
    Btn,
    Table,
    Container,
    Loader,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: false,
      headers: [],
      staticHeaders: [
        'Volume restant',
        'Écart réel (%)',
        'Conformité',
      ],
      modalities: [],
      formattedModalities: [],
      fiche: null,
    }
  },

  emits: ['set-editable'],

  computed: {
    surfaceTraitee() {
      return this.task.essai.surface_application_longueur * this.task.essai.surface_application_largeur * this.task.essai.plan.nb_repetitions
    },
    volumeTheoriqueBouillie() {
      return (this.surfaceTraitee * this.volume_ha) / 10000
    },
  },

  mounted() {
    this.setComponent()
  },
  methods: {
    setComponent() {
      this.emitter.emit('open-loader')

      this.taskService.setFormService(this.formService)
      this.taskService.setFileService(this.fileService)
      this.taskService.setRoute(this.$route)
      this.taskService.getVolumesNonEpandusFiche().then((fp) => {
        this.mode_calcul = fp.calcul_volume_applique === true ? 'volume_applique' : 'volume_restant'
        this.ecart_tolere = fp.ecart_tolere
        this.volume_ha = fp.volume_ha
        this.fiche_volume_non_epandus_id = fp.id
        this.fiche = fp
        this.taskService.getVolumesNonEpandusModalities().then((rm) => {
          this.modalities = rm
          this.taskService.getVolumesNonEpandusFormattedModalities(
            this.modalities,
            this.volumeTheoriqueBouillie,
            this.ecart_tolere,
            this.mode_calcul,
          ).then((rfm) => {
            this.formattedModalities = rfm
            this.emitter.emit('close-loader')
          })
        })
      })
    },

    download(type, ressource, rid) {
      this.taskService.downloadVolumeNonEpandusFiche(type, ressource, rid)
    },
  },
}
</script>
